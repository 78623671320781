/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Helmet>
          <script src='skripto.js' type="text/javascript" />
        </Helmet>
        <div
          style={{
            paddingTop: 0,
          }}
        >
        <main>{children}</main>
        </div>
          <footer 
            className='footer'
            style={{
            padding: `0.5px 1rem`,
            color: `#5b6c5d`,
            fontSize: `.8em`,
            background: `black`,
            }}>
            built at <a href="https://radicante.media">radicante.media</a> | <a href="https://airtable.com/tbliB3qFzJDtBt7K9/viwl8bNz4F4ArnNn3"> source spreadsheet </a>
          </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
